import { useMemo } from 'react';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';

export function useGetOrganisationMenuStructure() {
  const { currentOrganisationId: id } = useOrganisationSelector();
  const { hasPermission } = useHasPermission();

  const tabs = useMemo(() => {
    if (!id) return;
    return [
      { label: 'Home', to: '' },
      {
        label: 'Meetings',
        to: 'meetings/upcoming',
        hidden: !hasPermission('view-meetings', 'organisation', id),
      },
      {
        label: 'Actions',
        to: 'actions/open',
        hidden: !hasPermission('view-action-register', 'organisation', id),
      },
      {
        label: 'Documents',
        to: 'documents/all',
        hidden: !hasPermission(
          'view-organisation-documents',
          'organisation',
          id,
        ),
      },

      {
        label: 'Risk register',
        to: 'risk-register',
        hidden: !hasPermission('view-risk-register', 'organisation', id),
      },
      {
        label: 'Compliance checklists',
        to: 'checklists',
        hidden: !hasPermission('view-checklists', 'organisation', id),
      },
      {
        label: 'My organisation',
        to: 'organisation-profile',
        links: [
          {
            label: 'Organisation profile',
            to: 'organisation-profile',
            hidden: !hasPermission(
              'view-organisation-profile',
              'organisation',
              id,
            ),
          },
          {
            label: 'Reports',
            to: 'reports',
            hidden: !hasPermission(
              'view-organisation-reports',
              'organisation',
              id,
            ),
          },
          {
            label: 'Committees',
            to: 'committees',
            hidden: !hasPermission('view-committees-list', 'organisation', id),
          },
          {
            label: 'Members',
            to: 'members',
            hidden: !hasPermission(
              'view-organisation-members',
              'organisation',
              id,
            ),
          },
          {
            label: 'Partner details',
            to: 'partner-details',
            hidden: !hasPermission('view-partner-details', 'organisation', id),
          },
          {
            label: 'Academy seats',
            to: 'academy-seats',
            hidden: !hasPermission('manage-academy-seats', 'organisation', id),
          },
        ],
      },
    ];
  }, [id, hasPermission]);

  return { tabs };
}
