import { Mimetypes } from './mimetypes';

export type ImageParentEntity = 'organisation' | 'user';
export type DocumentParentEntity =
  | 'organisation'
  | 'committee'
  | 'meeting'
  | 'plan';
export type HistoricalStatusType = 'past' | 'current' | 'future';
export type ValueOf<T> = T[keyof T];

export type NestedObject<T = {}> = T & {
  [key: string]: NestedObject | string | boolean | number | null | undefined;
};

export interface IAcademySeatAllocation {
  id: number | string;
  member_id: number | string;
  allocated_by: number | string;
  allocated_at: string;
}

export type FeatureCode =
  | 'core'
  | 'checklists'
  | 'academy'
  | 'board'
  | 'risk-register'
  | 'action-register'
  | 'actions';
export type ManageFeatureAction = 'disable' | 'enable' | 'activate';

export const features: { [K in FeatureCode]: string } = {
  academy: 'Academy',
  board: 'Boards',
  'risk-register': 'Risk Register',
  'action-register': 'Action Register',
  checklists: 'Checklists',
  core: 'Core',
  actions: 'Actions',
};

// Feature settings interfaces
interface CoreSettings {
  max_members: number;
}

interface ChecklistsSettings {}

interface BoardSettings {}

interface RiskRegisterSettings {}

interface ActionRegisterSettings {}

interface ActionsSettings {}
interface AcademySettings {}

type FeatureSettings = {
  academy: AcademySettings;
  core: CoreSettings;
  checklists: ChecklistsSettings;
  board: BoardSettings;
  'risk-register': RiskRegisterSettings;
  actions: ActionsSettings;
  'action-register': ActionRegisterSettings;
};

// Trial settings
export type TrialSettings = {
  length: number;
  time_remaining: number;
  name: string;
  wizard_title: string;
  wizard_body: string;
  status: 'unused' | 'ongoing' | 'expired';
};

// Feature status type
export type FeatureStatus =
  | { status: 'allowed' | 'not-allowed' }
  | { status: 'trial'; trial_settings: TrialSettings };

// Feature base type
export type FeatureBase<T extends FeatureCode> = {
  code: T;
  name: string;
  settings: FeatureSettings[T];
  disabled: boolean;
  activated: boolean;
};

// Feature type
export type Feature<T extends FeatureCode = FeatureCode> = FeatureBase<T> &
  FeatureStatus;

// Organisation features type
type OrganisationFeatures = {
  [K in FeatureCode]: Feature<K>;
};

export type OrganisationPicker = Pick<IOrganisation, 'id' | 'name'>;

export interface IOrganisation {
  id: number | string;
  name: string;
  number: string;
  date_start: string;
  type: OrganisationType; // Assuming OrganisationType is defined elsewhere
  account_enabled: boolean;
  features: OrganisationFeatures;
  checklists: Array<IChecklistSetting>; // Assuming IChecklistSetting is defined elsewhere
  metadata: {
    [key: string]: any;
  };

  address_line_1: string;
  address_line_2?: string;
  premises: string;
  locality: string;
  region?: string;
  country?: string;
  postal_code: string;
  url?: string;

  partner_id: string | number;
  partner_name: string | number;
  plan_id: string | number;
  plan_name: string | number;

  checklists_activated: boolean;
  risk_register_activated: boolean;
  board_activated: boolean;

  partner: IPartner;

  created_at?: string;
  updated_at?: string;
}

export type IAbridgedOrganisation = Pick<
  IOrganisation,
  | 'id'
  | 'name'
  | 'address_line_1'
  | 'address_line_2'
  | 'premises'
  | 'locality'
  | 'region'
  | 'country'
  | 'postal_code'
  | 'url'
>;

export type IPartner = {
  id: number | string;
  name: string;

  logo_image: string;
  contact_details?: {
    contact_name: string;
    contact_email: string;
    telephone: string;
    premises?: string;
    address_line_1: string;
    address_line_2?: string;
    locality: string;
    region?: string;
    country?: string;
    postal_code?: string;
    website?: string;
  };
};

export interface IChecklistSetting {
  id: number;
  active?: boolean;
  due_date?: string;
}

export interface IAbridgedCommittee {
  id: number | string;
  name: string;
  organisation_id: number;
  organisation_name: string;
  member_count: number;
  created_at?: string;
  updated_at?: string;
}

export interface ICommittee {
  id: number | string;
  name: string;
  description: string;
  organisation_id: number;
  organisation_name: string;
  members: ICommitteeMember[];
  documents: IDocument[];
  is_default: boolean;
  is_full: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface IConflict {
  id: number | string;
  description: string;
  member_id: number;
  member_name: string;
  organisation_id: number | string;
  organisation_name: string;
  date_start: string;
  date_end: string | null;
  historical_status: HistoricalStatusType;

  created_at?: string;
  updated_at?: string;
}

export interface IInvitation {
  id: number | string;
  user_id?: number | string;
  organisation_id: number | string;
  organisation_name: string;
  first_name: string;
  last_name: string;
  job_title: string;
  email: string;
  last_sent: string;
  code: string;

  accepted_at?: string;
  declined_at?: string;
  created_at?: string;
  updated_at?: string;
}

export type ActionStatus = 'pending' | 'dismissed' | 'completed';

export interface IActionRegisterItem {
  id: number | string;
  reference: string;

  description: string;

  organisation_id: number | string;
  committee_id: number | string;
  committee_name: string;
  meeting_id: number | string;

  creator_id: number | string;

  created_at?: string;
  updated_at?: string;
  due_at?: string;
  closed_at?: string;

  owner_names?: Array<string>;

  members?: Array<IMember>;

  status?: 'open' | 'closed';

  assignee_text?: string;
}

export interface IAction {
  id: number | string;
  action_definition: IActionDefinition;

  subtask_statuses: { id: number; status?: ActionStatus }[];

  due_at?: string;
  completed_at?: string;
  dismissed_at?: string;
  updated_at?: string;
  created_at?: string;
  status: ActionStatus;
}

export interface IActionSubtask {
  id: number;
  name: string;
  onclick?: string;
  onclick_parameters?: Record<string, string>;
}

export interface IActionDefinitionTrigger {
  id?: number;
  type: string;
  parameters?: Record<string, string>;
}

export interface IActionSubtaskWithStatus extends IActionSubtask {
  status?: ActionStatus;
}

export interface IActionDefinition {
  id: number;
  context?: string;
  name: string;
  description?: string;
  pinned?: boolean;
  enabled?: boolean;
  due_date_offset?: string;
  due_date?: string;
  onclick?: string;
  onclick_parameters?: Record<string, string>;
  priority: number;
  subtasks?: IActionSubtask[];
  restriction_type?: string;
  restriction_code?: string;
  triggers?: IActionDefinitionTrigger[];
  context_ids?: number[];
  target?: 'organisation' | 'members' | 'personal';

  from_name?: string;

  completed_live_actions?: number;
  total_live_actions?: number;

  created_at?: string;
  updated_at?: string;
}

export interface ICalendarLinks {
  ics: string;
  google: string;
  webOutlook: string;
  webOffice: string;
}

export type MinutesStatus = 'missing' | 'draft' | 'pending' | 'final';

export interface IMeeting {
  id: number | string;
  organisation_id: number | string;
  organisation_name: string;
  committee_id: number | string;
  committee: Pick<ICommittee, 'id' | 'name'>;

  name: string;
  location: string;
  date_start: string;
  date_end: string;
  length?: number;
  description: string;
  historical_status: HistoricalStatusType;

  calendar_links: ICalendarLinks;

  documents: IDocument[];
  agenda_document?: IDocument;
  minutes_document?: IDocument;
  previous_minutes_document?: IDocument;

  previous_minutes_status?: MinutesStatus;

  previous_meeting_id?: number | string;

  previous_meeting_name?: string;
  previous_meeting_date?: string;

  conflicts: IConflict[];

  last_shared: string;

  updates?: IMeetingUpdate[];

  published_at?: string;

  log_updates?: boolean;
  notify_on_updates?: boolean;

  minutes_status?: MinutesStatus;

  created_at?: string;
  updated_at?: string;
}

export type IAbridgedMeeting = Pick<
  IMeeting,
  | 'id'
  | 'name'
  | 'description'
  | 'organisation_id'
  | 'organisation_name'
  | 'location'
  | 'date_start'
  | 'date_end'
  | 'historical_status'
  | 'committee'
  | 'calendar_links'
  | 'published_at'
>;

export type IMeetingUpdate = {
  meeting_id: number | string;
  user_id: number | string;
  user_name: string;
  description: string;
  owner_type: 'system' | 'user';

  created_at?: string;
  updated_at?: string;
};

export type IUserNotification = {
  id: number | string;
  user_id: number | string;
  context_type: string;
  context_identifier: string;
  data: any;
  read_at?: string;
  sent_at?: string;
  created_at: string;
};

export interface IFile {
  uuid: string;
  filename: string;
  url?: string;
  size: number;
  mimetype: ValueOf<Mimetypes>;
  extension: string;

  created_at?: string;
  updated_at?: string;
}

export interface IDocument {
  id: number | string;
  name: string;
  description?: string;

  categories?: string[];

  // file: string | IFile;
  file: string | IFile;
  file_details: IFile;

  parent_entity_type: DocumentParentEntity;
  parent_entity_id: number | string;

  organisation?: IOrganisation;
  committee?: ICommittee;
  meeting?: IMeeting;

  expires_at?: string;
  review_at?: string;

  created_at?: string;
  updated_at?: string;
}

export interface IImage {
  file: IFile;
  id: number | string;

  parent_entity_type: ImageParentEntity;
  parent_entity_id: number | string;

  created_at?: string;
  updated_at?: string;
}

export interface ISkill {
  id: number | string;

  description: string;
  member_name: string;
  organisation_name: string;
  created_at?: string;
  updated_at?: string;
}

export interface IMember {
  id: number | string;
  role_id: number | string;
  role_name: string;
  status: 'active' | 'suspended';
  first_name: string;
  last_name: string;
  job_title: string;
  created_at?: string;
  updated_at?: string;
}

export interface ICommitteeMember extends IMember {
  member_id: number | string;
}

export interface IRole {
  code: string;
  name: string;

  created_at?: string;
  updated_at?: string;
}

export type IOnboardingContent = NestedObject<{
  intro_text: string;
  outro_text: string;
  video: {
    type: 'youtube' | 'vimeo';
    id: string;
  };
}>;

export type MfaMethod = 'auth-app' | 'email' | 'sms';

export type IUser = {
  id: number;
  uuid?: string;
  email: string;
  title: string;
  first_name: string;
  last_name: string;
  telephone_home: string;
  telephone_mobile: string;
  telephone_work: string;
  occupation: string;
  displayNag: boolean;
  nationality: string;
  permissions: Array<string>;
  pusher?: {
    app_key: string;
    cluster: string;
    tls: boolean;
  };
  access_organisations?: Array<AccessOrganisation>;
  registered_status: 'complete' | 'invited' | 'new' | 'onboarding';
  onboarding_content?: IOnboardingContent;
  invite_code?: string;

  days_since_last_password_reset: number;
  thinkific_enabled?: boolean;
  is_admin?: boolean;
  enforce_mfa?: boolean;
  show_mfa_nag?: boolean;
  mfa_method?: MfaMethod;
  created_at?: string;
  updated_at?: string;

  email_verification_status: 'unverified' | 'pending' | 'verified';
};

export type AccessOrganisation = {
  id: number;
  role_name: string;
  name: string;
  committees: Array<AccessCommittee>;
  permissions: Array<string>;
  member_since?: string;
};

export type AccessCommittee = {
  id: number;
  name: string;
  permissions: Array<string>;
};

export const organisationTypes = {
  ltd: 'Private limited company',
  charity: 'Charitable organisation',
  'charitable-incorporated-organisation-cio':
    'Charitable incorporated organisation (CIO)',
  'community-interest-company-cic': 'Community Interest Company (CIC)',
  'industrial-and-provident-society': 'Industrial and Provident society',
  'limited-liability-partnership': 'Limited liability partnership',
  'private-limited-by-guarantee-without-share-capital':
    'Private limited by guarantee without share capital',
  'scottish-charitable-incorporated-organisation':
    'Scottish charitable incorporated organisation',
  other: 'Other',
};

export type OrganisationType = keyof typeof organisationTypes;

export interface AbridgedChecklist {
  id: number;
  plan_id: number;
  name: string;
  status: 'active' | 'inactive';
  description?: string;
  submissions?: Array<Partial<ChecklistSubmission>>;
  enabled?: boolean;
  due_date?: string;
}

export type ChecklistSubmissionStatus = 'open' | 'submitted';
export type ChecklistQuestionResponseValue = 'Y' | 'N' | null;
export type ChecklistQuestionRagAnswerValue = 'R' | 'A' | 'G' | null;

export interface ChecklistSubmissionAnswerDefinition {
  title: string;
  short_title?: string;
  help_text?: string;
  show_comment?: boolean;
  comment_placeholder_text?: string;
  action_on?: 'Y' | 'N';
  description?: string;
  resource_text?: string;
  resource_url?: string;
  button_intro_text?: string;
  button_red_text?: string;
  button_amber_text?: string;
  button_green_text?: string;
}

export interface ChecklistSubmissionAnswerValues {
  response: ChecklistQuestionResponseValue;
  comment: string;
  rag_answer: ChecklistQuestionRagAnswerValue;
}

export interface ChecklistSubmissionAnswer {
  id: number;
  definition: ChecklistSubmissionAnswerDefinition;
  values: ChecklistSubmissionAnswerValues;
}

export interface ChecklistSubmission {
  id: number;

  submitted_at?: string;
  status: ChecklistSubmissionStatus;
  checklist_name: string;
  checklist_description: string;
  checklist_id: number;
  organisation_id: number;
  answers: Array<ChecklistSubmissionAnswer>;

  due_date: string;
}

export interface ITrainingRecordEntry {
  activated_at: string;
  completed: boolean;
  completed_at: string | null;
  course_id: number;
  course_name: string;
  banner_image_url: string;
  course_card_image_url: string;
  created_at: string;
  expired: boolean;
  expiry_date: string;
  id: number;
  is_free_trial: boolean;
  link: string;
  percentage_completed: string;
  started_at: string | null;
  updated_at: string;
  user_email: string;
  user_id: number;
  user_name: string;
}

export type IRiskSuggestion = {
  risk_area_code: string;
  name: string;
  impact_text: string;
};

export type IRiskRegister = {
  id: number;
  organisation_id: number;
  name: string;
  risk_areas: Array<IRiskArea>;
  items: Array<IRiskRegisterItem>;

  created_at?: string;
  updated_at?: string;
};

export type IRiskRegisterItem = {
  id: number;
  risk_area_code: string;
  risk_register_id: number;
  name: string;
  score_likelihood: number;
  score_impact: number;
  score_impact_mitigated: number;
  impact_text: string;
  control_measures_text: string;

  is_archived?: boolean;
  archive_reason?: string;
  archived_at?: string;
  archived_by?: string;

  owner_name: string;

  pinned?: boolean;

  order_by: number;

  open?: boolean;

  created_at?: string;
  updated_at?: string;
  due_date?: string;
};

export type RiskHeaderIcon =
  | 'financial'
  | 'governance'
  | 'environmental-external'
  | 'operational'
  | 'legal-regulatory-compliance'
  | 'default';

export type IRiskArea = {
  code: string;
  name: string;
  icon?: RiskHeaderIcon;
  items?: Array<IRiskRegisterItem>;
};

export type RiskAreaAction = {
  action: 'remove' | 'move-up' | 'move-down' | 'update' | 'add';
  code?: string;
  name?: string;
};

export interface IVideo {
  type: 'youtube' | 'vimeo';
  id: string;
  title?: string;
  description?: string;
  thumbnail_url?: string;
}

//
