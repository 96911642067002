import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IconTitledContent } from 'app/components/IconTitledContent';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useMemo } from 'react';
import { getInitials } from 'utils/initials';
import { ActionRegisterCommittee } from '../slice/types';
import { ActionRegisterFormType } from './index';
import { capitalize } from 'lodash';

interface Props {
  onClose: () => void;
  previous: () => void;
  committees: ActionRegisterCommittee[];
}

export function CheckAndFinish(props: Props) {
  const { onClose, previous, committees } = props;
  const { values } = useFormikContext<ActionRegisterFormType>();

  const committee = useMemo(() => {
    return committees.find(c => c.id === values.committee_id);
  }, [committees, values.committee_id]);

  const members = useMemo(() => {
    return committee?.members || [];
  }, [committees, values.committee_id]);

  const selectedMembers = useMemo(() => {
    return members.filter(m => values.member_ids.includes(m.member_id));
  }, [members, values.member_ids]);

  return (
    <>
      <Typography variant="h2">Check and finish</Typography>
      <Typography variant="body2">
        Please review all the information entered in the form before submitting.
        Ensure that all fields are correct and complete. If you need to make any
        changes, you can go back to previous steps to update the details.
      </Typography>

      <IconTitledContent title="Action">
        <Typography variant="body2">{values.description}</Typography>
      </IconTitledContent>
      <IconTitledContent title="Committee">
        <Typography variant="body2">{committee?.name}</Typography>
      </IconTitledContent>
      <IconTitledContent title="Due">
        <Typography variant="body2">
          {moment(values.due_at).format('DD/MM/YYYY')}
        </Typography>
      </IconTitledContent>
      {!!selectedMembers.length && (
        <IconTitledContent title="Assigned to">
          <Stack>
            {selectedMembers.map(m => (
              <Stack direction={'row'} alignItems={'center'}>
                <Avatar
                  sx={{
                    backgroundColor: 'common.beige',
                    color: 'common.black',
                  }}
                >
                  {getInitials(`${m.first_name} ${m.last_name}`)}
                </Avatar>
                <Typography variant="body2">{`${m.first_name} ${m.last_name}`}</Typography>
              </Stack>
            ))}
          </Stack>
        </IconTitledContent>
      )}
      <IconTitledContent title="Status">
        {!!values.status && (
          <Typography variant="body2">{capitalize(values.status)}</Typography>
        )}
      </IconTitledContent>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={previous}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth type="submit">
              Publish this action
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
