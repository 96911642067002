import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  title: string;
}

export function ActionStatusButton(props: Props) {
  const { sx, ...rProps } = props;
  return (
    <>
      <Button
        fullWidth
        {...rProps}
        variant="text"
        sx={{
          opacity: 0.8,
          px: 2,
          '&:hover, &.isActive': {
            opacity: 1,
          },
          ...sx,
        }}
      />
    </>
  );
}
