import { Edit, KeyboardArrowDownSharp } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { IAction, IActionRegisterItem, ICommittee } from 'types/types';
import { getInitials } from 'utils/initials';
import { ActionStatusButton } from '../../../components/ActionStatusButton';
import { capitalize, noop } from 'lodash';
import { getDateWIthFallbackString } from '../../../../utils/getDateWIthFallbackString';

interface Props {
  actions?: IActionRegisterItem[];
  manageableCommitteeIds?: number[];
  context?: 'default' | 'meeting' | 'dashboard';
  onStatusChange?: (id: number, status: 'open' | 'closed') => void;
  saving?: boolean;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}

export function ActionRegisterTable(props: Props) {
  const {
    actions,
    manageableCommitteeIds = [],
    context,
    onStatusChange = noop,
    onEdit = noop,
    saving = false,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statuses = [
    {
      value: 'open',
      label: 'Open',
    },
    {
      value: 'closed',
      label: 'Closed',
    },
  ];

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('sm'));

  const buttonStatusColor = (status: 'open' | 'closed') => {
    switch (status) {
      case 'open':
        return {
          backgroundColor: 'secondary.light',
          color: 'secondary.main',
          '&:hover, &.isActive': {
            backgroundColor: 'secondary.light',
            color: 'secondary.main',
          },
        };
      case 'closed':
        return {
          backgroundColor: 'success.light',
          color: 'success.main',
          '&:hover, &.isActive': {
            backgroundColor: 'success.light',
            color: 'success.main',
          },
        };
      default:
        return {};
    }
  };

  return (
    <Stack>
      <DataGridPro
        loading={saving}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No actions
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No actions
            </Stack>
          ),
        }}
        columns={[
          {
            field: 'reference',
            headerName: 'Ref No',
            minWidth: 100,
            flex: 0.1,
          },
          {
            field: 'description',
            minWidth: 150,
            headerName: 'Action',
            flex: 1,
            renderCell: params => {
              return manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              ) ? (
                <Button
                  size="small"
                  onClick={() => onEdit(params.id)}
                  variant="text"
                  endIcon={<Edit />}
                  sx={{
                    pl: 0,
                  }}
                >
                  <Typography variant={'body2'}>{params.value}</Typography>
                </Button>
              ) : (
                <Typography variant={'body2'}>{params.value}</Typography>
              );
            },
          },

          {
            field: 'committee_name',
            headerName: 'Committee',
            minWidth: 150,
            flex: 0.2,
            renderCell: params => {
              const committeePath = () => {
                if (context === 'default' || context === 'meeting') {
                  return `../../committees/${params.row.committee_id}`;
                } else if (context === 'dashboard') {
                  return `committees/${params.row.committee_id}`;
                }
              };
              return (
                <Button
                  size="small"
                  component={Link}
                  to={committeePath()}
                  variant="text"
                  sx={{ pl: 0 }}
                >
                  <Typography variant={'body2'}>
                    {params.row.committee_name}
                  </Typography>
                </Button>
              );
            },
          },
          {
            field: 'assignedTo',
            headerName: 'Assigned to',
            minWidth: 150,
            renderCell: params => {
              const avatarColor = index => {
                const colors = [
                  'secondary.light',
                  'success.light',
                  'primary.light',
                  'warning.light',
                ];
                return colors[index % 4];
              };

              const assignees = params.row.members.map(
                member => `${member.first_name} ${member.last_name}`,
              );

              if (assignees.length === 0) {
                return <Typography variant={'body2'}>No assignees</Typography>;
              }

              const getSurnameInitial = (name: string) => {
                const nameParts = name.split(' ');
                if (nameParts.length >= 2) {
                  return `${nameParts[0]} ${
                    nameParts[nameParts.length - 1][0]
                  }`;
                }
                return name;
              };

              return (
                <>
                  {assignees.length === 1 ? (
                    <Typography variant={'body2'}>
                      {getSurnameInitial(assignees[0])}
                    </Typography>
                  ) : (
                    <Box sx={{ overflowX: 'auto' }}>
                      <Stack direction={'row'} spacing={-1}>
                        {assignees.map((val, index) => (
                          <React.Fragment key={index}>
                            <Tooltip title={val}>
                              <Avatar
                                sx={{
                                  backgroundColor: avatarColor(index),
                                  color: 'black',
                                }}
                              >
                                {getInitials(val)}
                              </Avatar>
                            </Tooltip>
                          </React.Fragment>
                        ))}
                      </Stack>
                    </Box>
                  )}
                </>
              );
            },
          },
          {
            field: 'created_at',
            type: 'date',
            headerName: 'Opened',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.created_at);
              return new Date(dateValue);
            },
          },

          {
            field: 'due_at',
            type: 'date',
            headerName: 'Due',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.due_at);
              return new Date(dateValue);
            },
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 120,
            flex: 0.1,
            renderCell: params => {
              const status = params.value;
              const editable = manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              );
              return (
                <>
                  <ActionStatusButton
                    title={status}
                    onClick={handleClick}
                    endIcon={editable && <RiArrowDropDownLine size={25} />}
                    sx={{
                      ...buttonStatusColor(status),
                      pointerEvents: editable ? 'auto' : 'none',
                    }}
                  >
                    <Typography variant={'body2'} fontWeight={'bold'}>
                      {capitalize(status)}
                    </Typography>
                  </ActionStatusButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.32))',
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: 'right',
                      vertical: 'top',
                    }}
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom',
                    }}
                  >
                    {statuses.map(({ value, label }) => (
                      <MenuItem
                        value={value}
                        disabled={status === value}
                        onClick={() => onStatusChange(params.id, value)}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              );
            },
          },
        ]}
        columnVisibilityModel={{
          committee_name: context !== 'meeting',
          created_at: isXs,
        }}
        rows={[...actions]}
        autoHeight
        disableColumnMenu
        hideFooter
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'primary.lightest',
          },
        }}
      />
    </Stack>
  );
}
