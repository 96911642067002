import React from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { IMeeting } from '../../../types/types';
import moment from 'moment/moment';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { RiSettings2Fill } from 'react-icons/ri';
import { CheckboxWithLabelExtended } from '../../components/CheckboxWithLabelExtended';

interface FormType {
  publish: boolean;
  notify_on_updates: boolean;
  log_updates: boolean;
}

interface Props {
  onSubmit: (values: FormType) => void;
  saving?: boolean;
  meeting: IMeeting;
}

const schema = Yup.object().shape({
  publish: Yup.boolean(),
  notify_on_updates: Yup.boolean(),
  log_updates: Yup.boolean(),
});

export function MeetingSettings(props: Props) {
  const { onSubmit, saving, meeting } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const formRef = React.useRef<FormikProps<FormType>>();

  useEffectOnChange(
    () => {
      setDialogOpen(false);
      formRef.current?.setSubmitting(false);
    },
    saving,
    false,
  );

  const publishText = (
    <>
      <Stack spacing={1}>
        <Typography fontWeight={'bold'}>
          {meeting.name} ({meeting.committee.name})
        </Typography>
        <Typography>
          {moment(meeting.date_start).format('ddd D MMMM Y [from] HH:mm')} to{' '}
          {moment(meeting.date_end).format('HH:mm')}
        </Typography>
      </Stack>
    </>
  );

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Chip
          label={meeting.published_at ? 'Published' : 'Draft'}
          color={meeting.published_at ? 'success' : 'warning'}
        />
        {!meeting.published_at && (
          <Button
            variant="text"
            startIcon={<RiSettings2Fill />}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Publish
          </Button>
        )}
      </Stack>

      <Formik<FormType>
        innerRef={formRef}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(true);
        }}
        initialValues={{
          publish: false,
          log_updates: meeting.log_updates || false,
          notify_on_updates: meeting.notify_on_updates || false,
        }}
        enableReinitialize
        validationSchema={schema}
      >
        {({ isSubmitting, values, errors, dirty }) => {
          return (
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              fullWidth
              maxWidth={'md'}
            >
              <Form>
                <DialogTitle>Publish Meeting</DialogTitle>
                <DialogContent>
                  <Stack>
                    {meeting.published_at ? (
                      <Typography>
                        Published on{' '}
                        {formatParsedISO(
                          meeting.published_at,
                          'dd/MM/yyyy HH:mm',
                        )}
                      </Typography>
                    ) : (
                      <>
                        {publishText}

                        <Box>
                          <Field
                            type="checkbox"
                            component={CheckboxWithLabelExtended}
                            Label={{
                              label:
                                'Publish this meeting and send notifications to attendees?',
                              description: (
                                <>
                                  This action will send an email with a link to
                                  all registered members of the{' '}
                                  {meeting.committee.name} committee.{' '}
                                  <a
                                    href={'//help.governance360.com'}
                                    target={'_blank'}
                                  >
                                    Learn more
                                  </a>
                                </>
                              ),
                            }}
                            name={'publish'}
                          />
                        </Box>
                        <Paper
                          variant={'outlined'}
                          sx={{
                            p: 2,
                            pointerEvents: values.publish ? 'auto' : 'none',
                          }}
                        >
                          <Stack>
                            <Stack
                              spacing={1}
                              sx={{ opacity: values.publish ? 1 : 0.5 }}
                            >
                              <Typography fontWeight={'bold'}>
                                Meetings settings
                              </Typography>
                              <Typography>
                                You can change these settings later at the
                                bottom of the meeting page.
                              </Typography>
                            </Stack>
                            <Box>
                              <Stack spacing={1}>
                                <Box>
                                  <Field
                                    disabled={!values.publish}
                                    type="checkbox"
                                    component={CheckboxWithLabelExtended}
                                    Label={{
                                      label: 'Save updates to the meeting log',
                                      description:
                                        'This will save all updates to the meeting log for future reference.',
                                    }}
                                    name={'log_updates'}
                                  />
                                </Box>
                                <Box>
                                  <Field
                                    disabled={!values.publish}
                                    type="checkbox"
                                    component={CheckboxWithLabelExtended}
                                    Label={{
                                      label:
                                        'Always notify attendees of meeting log updates',
                                      description:
                                        'Meeting log updates will be sent to attendees as an email digest',
                                    }}
                                    name={'notify_on_updates'}
                                  />
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Paper>
                      </>
                    )}
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'text'}
                    type="reset"
                    onClick={() => {
                      setDialogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || saving || !dirty}
                  >
                    Publish meeting
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
}
