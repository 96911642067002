/**
 *
 * TitledField
 *
 */
import * as React from 'react';
import { ReactNode } from 'react';
import { Box, Grid, Stack, StackProps, Typography } from '@mui/material';

interface Props extends Pick<StackProps, 'sx'> {
  title: ReactNode;
  children: ReactNode;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl';
  width?: 1 | 2 | 3 | 4 | 5 | 6;
  visible?: boolean;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  alignTitle?: 'start' | 'center' | 'end';
}

export function TitledField(props: Props) {
  const {
    title,
    children,
    breakpoint = 'lg',
    width = 2,
    visible = true,
    direction = 'column',
    alignTitle = 'center',
    sx,
    ...rProps
  } = props;

  if (!visible) return null;

  return (
    <Box {...rProps}>
      {direction === 'column' ? (
        <Grid container direction={direction}>
          <Grid item xs={12} {...{ [breakpoint]: width }}>
            <Stack direction="row" alignItems={alignTitle} gap={1}>
              <Typography variant="h5">{title}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} {...{ [breakpoint]: 12 - width }}>
            {children}
          </Grid>
        </Grid>
      ) : (
        <Stack direction="row" alignItems={alignTitle}>
          <Box>
            <Typography variant="h5">{title}</Typography>
          </Box>
          {children}
        </Stack>
      )}
    </Box>
  );
}
