import React from 'react';
import { Button, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { CheckboxWithLabelExtended } from '../../components/CheckboxWithLabelExtended';

interface FormType {
  description: string;
  notify: boolean;
}

interface Props {
  onSubmit: (values: FormType) => void;
  loading?: boolean;
  showNotifyCheckbox?: boolean;
}

const schema = Yup.object().shape({
  description: Yup.string().required('This field is required'),
});

export function MeetingUpdateForm(props: Props) {
  const { onSubmit, loading, showNotifyCheckbox } = props;
  return (
    <Formik<FormType>
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={{
        description: '',
        notify: false,
      }}
      validateOnBlur={false}
      enableReinitialize
      validationSchema={schema}
    >
      {({ isValid, isSubmitting, values }) => {
        return (
          <Form>
            <Stack>
              <Field
                name="description"
                component={TextField}
                label="Add a meeting update"
                multiline
                rows={2}
              />

              {!!showNotifyCheckbox && (
                <Field
                  type="checkbox"
                  edge="start"
                  component={CheckboxWithLabelExtended}
                  Label={{
                    label: 'Send this notification to attendees?',
                    description:
                      'This will notify all meeting attendees of the update.',
                  }}
                  name={'notify'}
                  disabled={!values.description}
                />
              )}
              <Button
                type="submit"
                disabled={!isValid || isSubmitting || loading}
              >
                Add meeting update
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
