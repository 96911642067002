/**
 *
 * Signup
 *
 */
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSignupSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectSignup } from './slice/selectors';
import { useHasChanged } from 'utils/usePrevious';
import { SignupForm } from './SignupForm';
import {
  Box,
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { ISignupPayload } from './slice/types';
import { useSnackbar } from 'notistack';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { useNavigate } from 'react-router-dom';
import { AcceptForm } from './AcceptForm';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';
import { IOrganisation } from 'types/types';
import { NewOrganisationForm } from './NewOrganisationForm';
import { SelectPlan } from './SelectPlan';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { SignupDialog } from './SignupDialog';
import { ConfirmNewOrganisation } from './ConfirmNewOrganisation';
import { Logo } from 'app/components/Logo';
import { InvalidResponse } from 'app/components/InvalidResponse';
import moment from 'moment';
import useHandleApiResponse from 'utils/useHandleApiResponse';

export interface StepperTabs {
  key: string;
  label: ReactNode;
}

export type NewOrganisationValues = Partial<IOrganisation> & {
  orgSelectionMode?: string;
  confirmedMode?: boolean;
  selectedCompany?: any;
};

interface Props {}

export function Signup(props: Props) {
  const { actions } = useSignupSlice();
  const dispatch = useDispatch();
  const { loadUser } = useSelector(selectAuthProvider);
  const { data: user } = loadUser;

  const theme = useTheme();
  const xsView = useMediaQuery(theme.breakpoints.down('sm'));

  const [submittingMessage, setSubmittingMessage] = useState<ReactNode | null>(
    null,
  );

  const [redirect, setRedirect] = useState<string | undefined>(undefined);

  const [failed, setFailed] = useState<string | undefined>('');

  const [signupValues, setSignupValues] = useState<ISignupPayload | null>(null);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const [step, setStep] = useState<
    'organisation' | 'plan' | 'confirming' | null
  >(null);
  const [organisationValues, setOrganisationValues] =
    useState<NewOrganisationValues | null>(null);

  const [planPayload, setPlanPayload] = useState<
    | {
        planHash?: string;
        plan_id?: number;
        name?: string;
        partner_name?: string;
      }
    | undefined
  >();

  const [ready, setReady] = useState<boolean>(false);

  const snackbar = useSnackbar();
  const { code } = useParams<{ code?: string }>();
  const {
    getInvitation,
    signup,
    acceptInvitation,
    declineInvitation,
    createOrganisation,
    dismissOnboarding,
  } = useSelector(selectSignup);
  const navigate = useNavigate();
  const confirm = useConfirm();

  // console.log(getInvitation.data);

  const getInvitationLoadingChanged = useHasChanged(getInvitation.loading);
  const signupLoadingChanged = useHasChanged(signup.loading);

  const submitOrganisation = useCallback(() => {
    setSubmittingMessage(<>Creating your organisation.</>);
    dispatch(
      actions.createOrganisationRequest({
        ...organisationValues,
        planHash: planPayload.planHash,
        plan_id: planPayload.plan_id,
      }),
    );
  }, [organisationValues, user, planPayload]);

  const stepperTabs = useMemo(() => {
    const arr: Array<StepperTabs> = [];
    if (!ready) return arr;

    if (!user || (user && user.registered_status !== 'complete')) {
      arr.push({
        label: 'Your info',
        key: 'signup',
      });
    }

    if (code || (user && user.registered_status === 'invited')) {
      arr.push({
        label: 'Accept invitation',
        key: 'accept-invitation',
      });
    } else {
      arr.push({
        label: 'Organisation info',
        key: 'create-organisation',
      });
      arr.push({
        label: 'Select plan',
        key: 'select-plan',
      });
      arr.push({
        label: 'Confirm details',
        key: 'confirm-details',
      });
    }

    return arr;
  }, [code, user, ready]);

  const userRegistrationStatusChanged = useHasChanged(
    (user || {}).registered_status,
  );

  const userChanged = useHasChanged(user);

  useHandleApiResponse(acceptInvitation, null, {
    onSuccess: () => {
      setSubmittingMessage(<>Confirming your response - please wait.</>);
      setRedirect(`/organisation/${acceptInvitation.data.organisation_id}`);
      dispatch(authProviderActions.loadUserDataRequest());
    },
    onError: () => {
      setSubmittingMessage(<>Confirming your response - please wait.</>);
    },
  });

  useHandleApiResponse(declineInvitation, null, {
    onSuccess: () => {
      setSubmittingMessage(<>Confirming your response - please wait.</>);
      setRedirect(`/`);
      if (
        user.registered_status === 'invited' &&
        !user.access_organisations.length
      ) {
        dispatch(authProviderActions.userLogoutRequest());
      } else {
        dispatch(authProviderActions.loadUserDataRequest());
      }
    },
  });

  useHandleApiResponse(dismissOnboarding, null, {
    onSuccess: () => {
      setDismissed(true);
      dispatch(authProviderActions.loadUserDataRequest());
    },
  });

  useHandleApiResponse(createOrganisation, null, {
    onSuccess: () => {
      dispatch(authProviderActions.loadUserDataRequest());
      setTimeout(() => {
        navigate(`/organisation/${createOrganisation.data.id}`);
      }, 1500);
    },
    onError: () => {
      setSubmittingMessage(null);
    },
  });

  useEffect(() => {
    if (
      userRegistrationStatusChanged &&
      user &&
      user.registered_status === 'complete' &&
      dismissed
    ) {
      navigate('/');
      return;
    }

    if (userChanged) {
      if (redirect) {
        setRedirect(undefined);
        navigate(redirect);
      }
      if (!code && !!(user && user.invite_code)) {
        navigate(`/signup/${user.invite_code}`);
        return;
      }
    }

    if (getInvitationLoadingChanged && !getInvitation.loading) {
      setReady(true);
      if (getInvitation.data?.accepted_at) {
        setFailed(
          `This invitation has already been accepted on ${moment(
            getInvitation.data.accepted_at,
          ).format('DD MMM YYYY, [at] HH:MM')}`,
        );
      } else if (getInvitation.error) {
        if (getInvitation.error.code == 404) {
          setFailed('This invitation link was not found.');
        } else {
          setFailed('This invitation link has encountered an error.');
        }
      } else {
        setFailed('');
        const email = encodeURIComponent(getInvitation.data.email);
        const loginWithCodePath = `/auth/${code}?invitation=1&email=${email}&redirect=/signup/${code}`;

        if (getInvitation.data.user_id) {
          if (user && user.id !== getInvitation.data.user_id) {
            // Navigate away as this invitation isn't for this user
            navigate(`/logout?redirect=/signup/${code}`);
            return;
          }

          if (!user) {
            navigate(loginWithCodePath);
            return;
          }
        }

        if (
          user &&
          user.email?.toLowerCase() !== getInvitation.data.email?.toLowerCase()
        ) {
          // Navigate away as this invitation isn't for this user
          navigate(`/logout?redirect=/signup/${code}`);
          return;
        }
      }
    }

    if (signupLoadingChanged && !signup.loading) {
      if (signup.error) {
        setSignupValues(null);
        snackbar.enqueueSnackbar(signup.error.message, { variant: 'error' });
      } else {
        if (signup.data) {
          setSignupValues(null);
        }
      }
    }
  });

  useEffect(() => {
    if (!code) {
      setReady(true);
    } else {
      setTimeout(() => dispatch(actions.getInvitationRequest({ code })), 20);
    }
    return () => {
      dispatch(actions.getInvitationInit());
      snackbar.closeSnackbar();
    };
  }, [code]);

  const content: { key: string; title: ReactNode; component: any } =
    useMemo(() => {
      if (getInvitation.data && user && user.invite_code === code)
        return {
          key: 'accept-invitation',
          title: 'Accept invitation',
          component: (
            <AcceptForm
              saving={
                declineInvitation.loading ||
                acceptInvitation.loading ||
                loadUser.loading ||
                !!redirect
              }
              onAccept={() => {
                dispatch(
                  actions.acceptInvitationRequest({
                    id: getInvitation.data.id,
                  }),
                );
              }}
              onDecline={() => {
                confirm({
                  title: 'Decline invitation',
                  description: 'Are you sure?',
                })
                  .then(() =>
                    dispatch(
                      actions.declineInvitationRequest({
                        id: getInvitation.data.id,
                      }),
                    ),
                  )
                  .catch(noop);
              }}
              invitation={getInvitation.data}
            />
          ),
        };

      if (user && ['new', 'complete'].indexOf(user.registered_status) !== -1) {
        if (step === 'confirming') {
          return {
            key: 'confirm-details',
            title: 'Confirm details',
            component: (
              <ConfirmNewOrganisation
                saving={createOrganisation.loading}
                onConfirm={() => {
                  submitOrganisation();
                }}
                organisationDetails={organisationValues}
                planDetails={planPayload}
                onDismiss={() => {
                  setStep('plan');
                }}
              />
            ),
          };
        }
        if (step === 'plan') {
          return {
            key: 'select-plan',
            title: 'Select plan',
            component: (
              <SelectPlan
                planDetails={planPayload}
                onSelect={payload => {
                  setPlanPayload(payload);
                  setStep('confirming');
                }}
                saving={createOrganisation.loading || loadUser.loading}
                onDismiss={() => setStep('organisation')}
              />
            ),
          };
        }
        return {
          key: 'create-organisation',
          title: 'Organisation info',
          component: (
            <NewOrganisationForm
              organisationDetails={organisationValues}
              onSubmit={values => {
                setOrganisationValues(values);
                setStep('plan');
              }}
            />
          ),
        };
      }

      return {
        key: 'signup',
        title: 'Sign up to Governance360',
        component: (
          <SignupForm
            onSubmit={values => {
              setSignupValues(values);
            }}
            saving={signup.loading || loadUser.loading}
            invitation={getInvitation.data}
          />
        ),
      };
    }, [
      user,
      code,
      getInvitation,
      ready,
      declineInvitation,
      acceptInvitation,
      signup,
      signupValues,
      organisationValues,
      createOrganisation,
      planPayload,
      step,
      submittingMessage,
    ]);
  const currentStep = stepperTabs.findIndex(tab => tab.key === content.key);

  if (getInvitation.loading || !ready) {
    return <LoadingIndicator />;
  }

  if (submittingMessage) {
    return <LoadingIndicator message={submittingMessage} />;
  }

  return (
    <>
      <Container maxWidth={'md'} sx={{ my: 4 }}>
        <Box>
          {failed ? (
            <InvalidResponse errorMessage={failed} />
          ) : (
            <Stack spacing={6}>
              <Stack alignItems={'center'} spacing={4}>
                <Logo variant="circle-blue" />
                <Stack direction={'row'} justifyContent={'center'}>
                  <Stepper activeStep={currentStep} alternativeLabel={xsView}>
                    {stepperTabs.map(step => (
                      <Step key={step.key}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </Stack>
              <Typography variant={'h1'} align={'center'}>
                {content.title}
              </Typography>
              {content.component}
            </Stack>
          )}
        </Box>
      </Container>
      <SignupDialog
        open={!!signupValues}
        loading={signup.loading}
        onDismiss={() => setSignupValues(null)}
        onAccept={() =>
          dispatch(
            actions.signupRequest({
              ...signupValues,
              invitation_code: code,
            }),
          )
        }
        code={code}
      />
    </>
  );
}
