/**
 *
 * Item
 *
 */
import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useConfirm } from 'utils/useConfirm';
import { RiAddCircleFill, RiDragDropFill } from 'react-icons/ri';
import Pin from '../../components/RiskRegisterItem/assets/pin_fill.svg';
import { useMeetingsSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { IOrganisation, MinutesStatus } from 'types/types';
import { useParams } from 'react-router';
import { useHasChanged } from 'utils/usePrevious';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import moment from 'moment';
import { ConflictsTable } from 'app/components/ConflictsTable';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { Link, useNavigate } from 'react-router-dom';
import { AddToCalendar } from 'app/components/AddToCalendar';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { MeetingDocument } from './MeetingDocument';
import { noop } from 'lodash';
import { Edit } from '@mui/icons-material';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import FileUploadComponent from '../../components/FileUpload/FileUploadComponent';
import LinkRenderer from './LinkRenderer';
import { useSnackbar } from 'notistack';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { MeetingUpdateForm } from './MeetingUpdateForm';
import key from 'weak-key';
import { MeetingSettings } from './MeetingSettings';
import { CustomAccordion } from 'app/components/CustomAccordion';

import { ActionRegister } from 'app/widgets/ActionRegister';
import { useSettings } from 'app/providers/SettingsProvider';

import { MeetingLogOptions } from './MeetingLogOptions';
import { TitledField } from 'app/components/TitledField';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const { organisation } = props;

  const { actions } = useMeetingsSlice();
  const {
    loadMeeting,
    deleteMeeting,
    deleteDocument,
    reorderDocuments,
    saveDocument,
    createMeetingUpdate,
    updateMeetingSettings,
  } = useSelector(selectMeetings);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const { hasPermission } = useHasPermission();

  const { id } = useParams();

  const { open, close, isOpen } = useMeetingFormWidget();

  const loadMeetingLoadingChanged = useHasChanged(loadMeeting.loading);
  const idChanged = useHasChanged(id);

  const deleteDocumentCB = useCallback(
    (documentId: number) => {
      confirm({
        title: 'Delete document',
        description: 'Are you sure?',
      })
        .then(() => {
          dispatch(
            actions.deleteDocumentRequest({ meetingId: +id, documentId }),
          );
        })
        .catch(noop);
    },
    [loadMeeting.data],
  );

  const saveDocumentCB = useCallback(
    (p: {
      name: string;
      file: File;
      description: string;
      documentId?: number;
      category: 'agenda' | 'minutes' | 'previous-minutes' | 'working-paper';
    }) => {
      dispatch(actions.saveDocumentRequest({ meetingId: +id, ...p }));
    },
    [loadMeeting.data],
  );

  const { settings, setSetting } = useSettings();
  const reorderDocumentsCB = useCallback(
    (oldIndex: number, newIndex: number, row: any) => {
      dispatch(
        actions.reorderDocumentsRequest({
          meetingId: +id,
          oldIndex,
          newIndex,
          row,
        }),
      );
    },
    [loadMeeting.data],
  );

  useEffect(() => {
    if (loadMeetingLoadingChanged && !loadMeeting.loading) {
      if (loadMeeting.error) {
        if (loadMeeting.error.code === 401) return;
        navigate('../', { replace: true });
      }
    }

    if (idChanged) {
      console.log(id, idChanged);
    }
    if (idChanged && id) {
      load();
    }
  });

  const load = useCallback(() => {
    dispatch(actions.loadMeetingRequest({ id: +id }));
  }, [organisation, id]);

  useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadMeetingInit());
    };
  }, [load]);

  useHandleApiResponse(deleteMeeting, 'Meeting deleted', {
    onSuccess: () => {
      close();
      navigate('../upcoming', { replace: true });
    },
    errorMessage: 'Unable to delete a meeting',
  });

  useHandleApiResponse(saveDocument, 'Document saved', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(deleteDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(reorderDocuments, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(createMeetingUpdate, 'Meeting updated', {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(updateMeetingSettings, null, {
    onSuccess: () => {
      load();
    },
  });

  if (!loadMeeting.data) {
    return <LoadingIndicator />;
  }

  const onToggle = (panel: string) => {
    const currentState = settings.accordionState || [];
    const newState = currentState.includes(panel)
      ? currentState.filter(item => item !== panel)
      : [...currentState, panel];

    setSetting('accordionState', newState);
  };

  const canManageDocuments = hasPermission(
    'manage-meeting-documents',
    'committee',
    loadMeeting.data.committee_id,
  );
  const canEditMeeting = hasPermission(
    'edit-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const isFutureMeeting = moment(loadMeeting.data.date_start).isAfter(moment());

  const canShareMeeting = hasPermission(
    'share-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  const canDeleteMeeting = hasPermission(
    'delete-meeting',
    'committee',
    loadMeeting.data.committee_id,
  );

  return (
    <>
      <Helmet title={loadMeeting.data.name}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>

      <Box sx={{ my: 4 }}>
        <Stack spacing={4}>
          <Box>
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Typography variant={'h3'}>{loadMeeting.data.name}</Typography>
              {canEditMeeting && (
                <>
                  <Button
                    variant="text"
                    startIcon={<Edit />}
                    onClick={() =>
                      open({ id, organisation_id: organisation.id })
                    }
                  >
                    Edit details
                  </Button>
                  <MeetingFormDialog
                    open={isOpen}
                    onClose={close}
                    onDelete={
                      canDeleteMeeting
                        ? meeting => {
                            confirm({
                              title: 'Delete meeting',
                              description: 'Are you sure?',
                            })
                              .then(() => {
                                dispatch(
                                  actions.deleteMeetingRequest({
                                    id: meeting.id,
                                  }),
                                );
                              })
                              .catch(() => {});
                          }
                        : null
                    }
                    onChange={load}
                  />
                </>
              )}
              {canShareMeeting && (
                <Box sx={{ ml: 'auto !important' }}>
                  <MeetingSettings
                    onSubmit={values =>
                      dispatch(
                        actions.updateMeetingSettingsRequest({
                          id: loadMeeting.data.id,
                          ...values,
                        }),
                      )
                    }
                    meeting={loadMeeting.data}
                    saving={updateMeetingSettings.loading}
                  />
                </Box>
              )}
            </Stack>
          </Box>

          <Paper
            variant={'outlined'}
            square
            sx={{
              px: 2,
              pt: 2,
              pb: 4,
              backgroundColor: 'common.beige',
            }}
          >
            <Stack spacing={3}>
              <Box>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={4}>
                      <Box>
                        <Stack direction={'row'} alignItems={'center'}>
                          <CardMedia
                            component={'img'}
                            src={Pin}
                            sx={{ width: 20, height: 20 }}
                          />
                          <Typography color={'primary.main'}>
                            Essential info
                          </Typography>
                        </Stack>
                      </Box>

                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={5}
                        alignItems={{ xs: 'start', md: 'center' }}
                      >
                        <Typography fontWeight={'bold'}>
                          {moment(loadMeeting.data.date_start).format(
                            'ddd DD[th] MMM YYYY',
                          )}
                        </Typography>

                        <Typography fontWeight={'bold'}>
                          {moment(loadMeeting.data.date_start).format('HH:mm')}{' '}
                          - {moment(loadMeeting.data.date_end).format('HH:mm')}
                        </Typography>

                        <Typography fontWeight={'bold'}>
                          {loadMeeting.data.committee.name}
                        </Typography>
                      </Stack>
                      {loadMeeting.data.description && (
                        <Typography variant="body2">
                          {loadMeeting.data.description}
                        </Typography>
                      )}
                      <Box>
                        <Stack>
                          <Typography>Location</Typography>

                          <Box>
                            <Typography variant="h5">
                              <LinkRenderer
                                location={
                                  loadMeeting.data.location
                                    ? loadMeeting.data.location
                                    : 'Not described'
                                }
                              />
                            </Typography>
                          </Box>
                          {isFutureMeeting && (
                            <AddToCalendar
                              calendarView={'list'}
                              buttonProps={{
                                size: 'small',
                              }}
                              calendar_links={loadMeeting.data.calendar_links}
                            />
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Paper
                      variant={'outlined'}
                      sx={{
                        height: '100%',
                        p: 2,
                      }}
                      square
                    >
                      <Stack>
                        <Typography variant={'h5'}>Agenda</Typography>
                        <MeetingDocument
                          loading={
                            saveDocument.loading || deleteDocument.loading
                          }
                          canManage={canManageDocuments}
                          initialDocument={loadMeeting.data?.agenda_document}
                          onSave={values =>
                            saveDocumentCB({ ...values, category: 'agenda' })
                          }
                          onDelete={() =>
                            deleteDocumentCB(
                              +loadMeeting.data?.agenda_document.id,
                            )
                          }
                        />
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Stack>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Paper
                          square
                          variant="outlined"
                          sx={{ p: 2, height: '100%' }}
                        >
                          <Stack spacing={3}>
                            <Typography variant="h5">
                              Minutes from previous meeting
                              {!!loadMeeting.data.previous_meeting_id && (
                                <>
                                  :{' '}
                                  <Link
                                    to={`../${loadMeeting.data.previous_meeting_id}`}
                                  >
                                    {loadMeeting.data.previous_meeting_name}
                                  </Link>
                                </>
                              )}
                            </Typography>
                            <Box>
                              {!!loadMeeting.data.previous_meeting_id ? (
                                <Stack>
                                  <MeetingDocument
                                    loading={
                                      saveDocument.loading ||
                                      deleteDocument.loading
                                    }
                                    canManage={canManageDocuments}
                                    initialDocument={
                                      loadMeeting.data
                                        ?.previous_minutes_document
                                    }
                                    onSave={values =>
                                      saveDocumentCB({
                                        ...values,
                                        category: 'previous-minutes',
                                      })
                                    }
                                    onDelete={() =>
                                      deleteDocumentCB(
                                        +loadMeeting.data
                                          ?.previous_minutes_document.id,
                                      )
                                    }
                                  />
                                  {!!loadMeeting.data
                                    ?.previous_minutes_document && (
                                    <Box>
                                      {canEditMeeting ? (
                                        <TitledField width={1} title="Status">
                                          <Select
                                            fullWidth
                                            value={
                                              loadMeeting.data
                                                .previous_minutes_status
                                            }
                                            onChange={e => {
                                              dispatch(
                                                actions.updateMeetingSettingsRequest(
                                                  {
                                                    id: loadMeeting.data.id,
                                                    previous_minutes_status: e
                                                      .target
                                                      .value as MinutesStatus,
                                                  },
                                                ),
                                              );
                                            }}
                                          >
                                            {[
                                              {
                                                value: 'draft',
                                                label: 'Draft',
                                                subtext: 'subject to agreement',
                                              },
                                              {
                                                value: 'pending',
                                                label: 'Pending',
                                                subtext:
                                                  'minutes are awaiting approval by the chair',
                                              },
                                              {
                                                value: 'final',
                                                label: 'Final',
                                                subtext: `visible to all members of the ${loadMeeting.data.committee.name} committee`,
                                              },
                                            ].map(item => (
                                              <MenuItem
                                                key={item.value}
                                                value={item.value}
                                              >
                                                <Stack
                                                  spacing={1}
                                                  direction={'row'}
                                                  alignItems={'center'}
                                                >
                                                  <Typography>
                                                    {item.label}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    sx={{
                                                      color: theme =>
                                                        theme.palette.grey[500],
                                                    }}
                                                  >
                                                    {item.subtext}
                                                  </Typography>
                                                </Stack>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </TitledField>
                                      ) : (
                                        <Chip
                                          color={
                                            {
                                              draft: 'warning',
                                              pending: 'info',
                                              final: 'success',
                                            }[loadMeeting.data.minutes_status]
                                          }
                                          label={
                                            loadMeeting.data.minutes_status
                                          }
                                          sx={{
                                            textTransform: 'uppercase',
                                            color: 'common.white',
                                          }}
                                        />
                                      )}
                                    </Box>
                                  )}
                                </Stack>
                              ) : (
                                <Typography color="textSecondary">
                                  No previous meeting found.
                                </Typography>
                              )}
                            </Box>
                          </Stack>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Paper
                          square
                          variant="outlined"
                          sx={{ p: 2, height: '100%' }}
                        >
                          <Stack spacing={3}>
                            <Typography variant="h5">
                              Current minutes
                            </Typography>
                            <MeetingDocument
                              negativeStatement="No minutes attached"
                              loading={
                                saveDocument.loading || deleteDocument.loading
                              }
                              canManage={canManageDocuments}
                              initialDocument={
                                loadMeeting.data?.minutes_document
                              }
                              onSave={values =>
                                saveDocumentCB({
                                  ...values,
                                  category: 'minutes',
                                })
                              }
                              onDelete={() =>
                                deleteDocumentCB(
                                  +loadMeeting.data?.minutes_document.id,
                                )
                              }
                            />

                            {!!loadMeeting.data?.minutes_document && (
                              <Box>
                                {canEditMeeting ? (
                                  <TitledField width={1} title="Status">
                                    <Select
                                      fullWidth
                                      value={loadMeeting.data.minutes_status}
                                      onChange={e => {
                                        dispatch(
                                          actions.updateMeetingSettingsRequest({
                                            id: loadMeeting.data.id,
                                            minutes_status: e.target
                                              .value as MinutesStatus,
                                          }),
                                        );
                                      }}
                                    >
                                      {[
                                        {
                                          value: 'draft',
                                          label: 'Draft',
                                          subtext: 'subject to agreement',
                                        },
                                        {
                                          value: 'pending',
                                          label: 'Pending',
                                          subtext:
                                            'minutes are awaiting approval by the chair',
                                        },
                                        {
                                          value: 'final',
                                          label: 'Final',
                                          subtext: `visible to all members of the ${loadMeeting.data.committee.name} committee`,
                                        },
                                      ].map(item => (
                                        <MenuItem
                                          key={item.value}
                                          value={item.value}
                                        >
                                          <Stack
                                            spacing={1}
                                            direction={'row'}
                                            alignItems={'center'}
                                          >
                                            <Typography>
                                              {item.label}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                color: theme =>
                                                  theme.palette.grey[500],
                                              }}
                                            >
                                              {item.subtext}
                                            </Typography>
                                          </Stack>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </TitledField>
                                ) : (
                                  <Chip
                                    color={
                                      {
                                        draft: 'warning',
                                        pending: 'info',
                                        final: 'success',
                                      }[loadMeeting.data.minutes_status]
                                    }
                                    label={loadMeeting.data.minutes_status}
                                    sx={{
                                      textTransform: 'uppercase',
                                      color: 'common.white',
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                          </Stack>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Paper>
          <Box>
            <Stack spacing={4}>
              <CustomAccordion
                open={settings.accordionState?.includes('working-papers')}
                onToggle={() => onToggle('working-papers')}
                title="Working Papers"
                children={
                  <>
                    <Stack spacing={2}>
                      <Box>
                        {!!loadMeeting.data.documents?.length ? (
                          <DataGridPro
                            autoHeight
                            getRowHeight={() => 80}
                            slots={{
                              columnHeaders: React.forwardRef((props, ref) => (
                                <Box ref={ref} />
                              )),
                            }}
                            columnVisibilityModel={{
                              actions: canManageDocuments,
                            }}
                            rowReordering={canManageDocuments}
                            onRowOrderChange={({
                              oldIndex,
                              targetIndex,
                              row,
                            }) => {
                              reorderDocumentsCB(oldIndex, targetIndex, row);
                            }}
                            hideFooter
                            columns={[
                              // { field: 'order_by' },
                              {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 200,
                                flex: 0.8,
                                sortable: false,
                                renderCell: params => (
                                  <MeetingDocument
                                    loading={
                                      saveDocument.loading ||
                                      deleteDocument.loading ||
                                      reorderDocuments.loading
                                    }
                                    canManage={canManageDocuments}
                                    initialDocument={params.row}
                                    onSave={values =>
                                      saveDocumentCB({
                                        ...values,
                                        documentId: params.id,
                                        category: 'default',
                                      })
                                    }
                                    onDelete={() =>
                                      deleteDocumentCB(+params.row.id)
                                    }
                                  />
                                ),
                              },
                            ]}
                            rows={loadMeeting.data?.documents}
                          />
                        ) : (
                          <>
                            <Box sx={{ p: 1 }}>
                              <Typography>No documents attached</Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                      {canManageDocuments && (
                        <Box
                          sx={{
                            border: 2,
                            borderColor: 'grey.200',
                            borderStyle: 'dashed',
                            borderRadius: 2,
                            p: 2,
                          }}
                        >
                          <FileUploadComponent
                            multiple
                            onErrorEvent={error =>
                              snackbar.enqueueSnackbar(error, {
                                variant: 'error',
                              })
                            }
                            loading={saveDocument.loading}
                            handleFileChange={file => {
                              dispatch(
                                actions.saveDocumentRequest({
                                  meetingId: +id,
                                  file,
                                  category: 'default',
                                }),
                              );
                            }}
                          >
                            <Stack spacing={1} alignItems={'center'}>
                              <Button startIcon={<RiAddCircleFill />}>
                                Select a Document to upload
                              </Button>
                              <Typography variant="body2">or</Typography>
                              <Button
                                variant="text"
                                sx={{
                                  color: theme => theme.palette.common.black,
                                  fontWeight: 'bold',
                                }}
                                startIcon={<RiDragDropFill />}
                              >
                                Drag & drop to upload
                              </Button>
                            </Stack>
                          </FileUploadComponent>
                        </Box>
                      )}
                    </Stack>
                  </>
                }
              />

              {hasPermission(
                'view-action-register-items',
                'committee',
                loadMeeting.data.committee_id,
              ) && (
                <CustomAccordion
                  title="Actions register"
                  open={settings.accordionState?.includes('action-register')}
                  onToggle={() => onToggle('action-register')}
                  children={
                    <Stack spacing={4}>
                      <Box>
                        <ActionRegister
                          organisation_id={loadMeeting.data.organisation_id}
                          meeting_id={loadMeeting.data.id}
                          committee_id={loadMeeting.data.committee_id}
                          viewMode={'meeting'}
                          introText={
                            <>
                              <Typography>
                                Here are all actions for:{' '}
                                <Typography component={'span'}>
                                  {loadMeeting.data.committee.name}
                                </Typography>
                              </Typography>
                            </>
                          }
                        />
                      </Box>
                    </Stack>
                  }
                />
              )}
            </Stack>
          </Box>

          <CustomAccordion
            title="Declarations of interest"
            open={settings.accordionState?.includes('doi')}
            onToggle={() => onToggle('doi')}
            children={
              <>
                <Stack spacing={3}>
                  <Typography>
                    Below you can view declarations of interest submitted by
                    members of the committee. These declarations provide
                    transparency regarding any personal, professional, or
                    financial relationships that could influence decisions in
                    your Organisation.
                  </Typography>
                  <ConflictsTable conflicts={loadMeeting.data.conflicts} />
                </Stack>
              </>
            }
          />

          {loadMeeting.data.published_at && (
            <CustomAccordion
              title="Meeting log"
              open={settings.accordionState?.includes('meeting-log')}
              onToggle={() => onToggle('meeting-log')}
              children={
                <Stack spacing={4} sx={{ mb: 2 }}>
                  {canEditMeeting && (
                    <>
                      <Box>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          alignItems={{ xs: 'start', md: 'center' }}
                          spacing={{ xs: 1, md: 4 }}
                        >
                          <MeetingLogOptions
                            initialLogUpdates={loadMeeting.data.log_updates}
                            initialNotifyOnUpdates={
                              loadMeeting.data.notify_on_updates
                            }
                            onCheck={values =>
                              dispatch(
                                actions.updateMeetingSettingsRequest({
                                  id: loadMeeting.data.id,
                                  ...values,
                                }),
                              )
                            }
                            saving={updateMeetingSettings.loading}
                          />
                        </Stack>
                      </Box>

                      {loadMeeting.data.updates.map(update => (
                        <Stack
                          spacing={2}
                          direction={'row'}
                          alignItems={'center'}
                          key={key(update)}
                        >
                          <Typography variant="body2">
                            <Box component={'span'} sx={{ fontWeight: 'bold' }}>
                              [{update.user_name || 'Unknown'}]{' '}
                            </Box>
                            {update.description || 'No description'}
                          </Typography>
                          <Chip
                            label={formatParsedISO(
                              update.created_at,
                              'dd/MM/yyyy HH:mm',
                            )}
                            sx={{
                              background: theme => theme.palette.grey[500],
                              color: 'common.white',
                            }}
                          />
                        </Stack>
                      ))}

                      <Box>
                        <MeetingUpdateForm
                          showNotifyCheckbox={
                            !loadMeeting.data?.notify_on_updates
                          }
                          loading={createMeetingUpdate.loading}
                          onSubmit={values => {
                            dispatch(
                              actions.createMeetingUpdateRequest({
                                meetingId: +id,
                                ...values,
                              }),
                            );
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Stack>
              }
            />
          )}

          {/* <CustomAccordion
            title="My notes"
            onToggle={() => onToggle('my-notes')}
            children={
              <Box>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Stack>
                      <Typography>
                        Only you can see your notes for this meeting.
                      </Typography>
                      <TextField multiline rows={5} />
                      <Box>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Button variant="outlined">Cancel</Button>
                          <Button startIcon={<RiAddCircleFill />}>
                            Add note
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={4}>
                      <Box>
                        <Stack>
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Typography>Tue 7 Jun, 15:55</Typography>
                            <Box>
                              <Edit />
                              <IconButton
                                aria-label={'Delete'}
                                onClick={() => alert('Delete')}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Stack>
                          <Typography variant="body2">
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Illum voluptate adipisci alias qui?
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack>
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Typography>Fri 19 May, 09:23</Typography>
                            <Box>
                              <Edit />
                              <IconButton
                                aria-label={'Delete'}
                                onClick={() => alert('Delete')}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Stack>
                          <Typography variant="body2">
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Illum voluptate adipisci alias qui?
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            }
          /> */}
        </Stack>
      </Box>
    </>
  );
}
